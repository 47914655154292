<template>
  <v-row wrap no-gutters>
    <text-templates-select
      :key="`${step.id}-textTemplate`"
      step-type="PDF"
      :properties="properties"
      step-label="pdf"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('textTemplate', $event)"
    />

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="pdf-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <locales-select
        :key="`${step.id}-locale`"
        :properties="properties"
        step-label="pdf"
        :readonly="!canEdit"
        :required="false"
        @change="handleChange('locale', $event)"
      />
    </v-col>

    <v-col cols="12" class="pb-3">
      <add-key-value
        :key="`${step.id}-textTemplate`"
        type="variables"
        :can-edit="canEdit"
        step-type="PDF"
        :data="properties.variables"
        :text-template-name="properties.textTemplate"
        :single-step="step"
        @dataChanged="handleChange('variables', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { TextTemplatesSelect, LocalesSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

export default {
  name: 'PdfStep',
  components: {
    TextTemplatesSelect,
    LocalesSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  created() {
    if (!this.properties.variables) this.$set(this.properties, 'variables', {})
  }
}
</script>
